<style scoped>
.materials-item {
  margin-bottom: 20px;
  overflow: hidden;
}
.card-content {
  text-align: left;
  /* display: flex;
  flex-direction: column; */
}
</style>
<template>
  <Row :gutter="16">
    <Col
      v-for="(diskInfo, index) in diskInfos"
      :key="index"
      :lg="{ span: 12 }"
      :xs="{ span: 24 }"
      span="12"
      class="materials-item"
    >
      <Card>
        <p slot="title">{{ diskInfo.title }}</p>
        <div class="card-content">
          <img :src="diskInfo.imageUrl" height="100px" />
          <div style="margin: 5px 0">
            链接: <a :href="diskInfo.url" target="_blank">{{ diskInfo.url }}</a>
          </div>
          <div>
            提取码: {{ diskInfo.passWord }}
            <Button
              size="small"
              style="color: #4c8bf5"
              @click="copyPass(diskInfo.passWord)"
            >
              <Icon type="ios-copy" />
            </Button>
          </div>
        </div>
      </Card>
    </Col>
    <!-- <Col span="11" offset="2">
            <Card dis-hover>
                <p slot="title">Disable card with hover shadows</p>
                <p>Content of card</p>
                <p>Content of card</p>
                <p>Content of card</p>
            </Card>
        </Col> -->
  </Row>
</template>
<script>
import monashIT from "../../assets/monash.jpeg";
import melbUniIT from "../../assets/melb_uni_IT.jpeg";
export default {
  data() {
    return {
      diskInfos: [
        {
          title: "尚学Monash大学校区商科资料",
          imageUrl: monashIT,
          url: "https://share.weiyun.com/5SRdaJo",
          passWord: "ckgxua"
        },
        {
          title: "尚学Monash大学校区IT资料",
          imageUrl: monashIT,
          url: "https://share.weiyun.com/5351ocN",
          passWord: "nnj33b"
        },
        {
          title: "尚学墨尔本大学校区IT资料",
          imageUrl: melbUniIT,
          url: "https://share.weiyun.com/5bsjzGx",
          passWord: "b3rtnr"
        }
      ]
    };
  },
  methods: {
    copyPass(password) {
      const el = document.createElement("textarea");
      el.value = password;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      // Message.success('订单号已复制');
      this.$Message.success("提取码已复制");
    }
  }
};
</script>
